import UIkit from "uikit";
import Icons from 'uikit/dist/js/uikit-icons';
import "./cookie";
UIkit.use(Icons);

// loads the Icon plugin

// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

